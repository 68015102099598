import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { FaClock, FaCheckCircle, FaTimesCircle, FaSignInAlt, FaCog } from 'react-icons/fa';

const SidebarContainer = styled.div`
  width: 250px;
  background-color: #2c3e50;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ecf0f1;
  position: relative;
  transition: width 0.2s ease;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 70px; 
  margin-bottom: 30px; 
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoTitle = styled.h1`
  font-size: 26px; /* Metin boyutu ayarlandı */
  font-weight: 600; /* Kalınlık azaltıldı */
  color: #e0e0e0; /* Daha koyu beyaz renk */
  text-align: center; /* Ortalı metin */
  margin: 0; /* Varsayılan margin kaldırıldı */
  font-family: 'Montserrat', sans-serif; /* Daha modern bir yazı tipi eklendi */
  text-transform: uppercase; /* Harfleri büyük hale getir */
  letter-spacing: 1px; /* Harfler arası boşluk azaltıldı */
  position: relative; /* Alt çizgi için gerekli */
  
  
  /* Alt çizgi efekti */
  &::after {
    content: '';
    position: absolute;
    left: 0; /* Sola yasla */
    bottom: -5px; /* Metnin altında */
    width: 100%; /* Başlangıçta çizgi genişliği */
    height: 4px; /* Çizgi yüksekliği */
    background: #b3b3b3; /* Çizgi rengi koyu beyaz */
    transform: scaleX(0); /* Başlangıçta gizle */
    transform-origin: left; /* Çizgi soldan sağa genişlesin */
    transition: transform 0.5s ease; /* Geçiş efekti */
    border-radius: 2px; /* Kenarları yuvarlat */
  }

  /* Hover durumunda çizginin genişlemesi */
  &:hover::after {
    transform: scaleX(1); /* Hover olduğunda genişlesin */
  }
`;






const NavButton = styled.button`
  position: relative;
  width: 100%;
  padding: 15px 20px;
  margin: 10px 0; 
  border: none; 
  background-color: #34495e; 
  color: #ecf0f1;
  font-size: 16px; 
  font-family: "Arial", sans-serif; 
  text-align: left; 
  border-radius: 8px; 
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.2s, box-shadow 0.2s;

  &:hover {
    background: linear-gradient(45deg, #16a085, #1abc9c); 
    color: #ffffff;
    transform: translateY(-2px); 
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); 
  }

  ${({ active }) => active && css`
    background-color: #16a085; 
    color: white;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2); 
  `}

  &::after {
    content: '';
    position: absolute;
    left: -4px; 
    top: 50%;
    transform: translateY(-50%);
    width: 4px; 
    height: 70%; 
    background-color: transparent; 
    transition: background-color 0.3s;
  }

  &:hover::after, ${({ active }) => active && css`
    &::after {
      background-color: #27ae60; 
    }
  `}
`;

const IconContainer = styled.span`
  margin-right: 10px; 
  font-size: 20px; /* İkon boyutunu artır */
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 15px 0; /* Üst ve alt margin */
`;

const Sidebar = ({ onSelect }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');

  const handleClick = (title, path) => {
    if (onSelect) {
      onSelect(title);
    }
    navigate(path);
  };

  return (
    <SidebarContainer>
      <LogoContainer>
        <LogoTitle>ANTIK</LogoTitle> {/* Modern metin eklendi */}
      </LogoContainer>
      <Divider /> {/* Logo ile butonlar arasına ayrım çizgisi eklendi */}
      {token ? (
        <>
          <NavButton 
            onClick={() => handleClick('Bekleyen Talepler', '/pending-requests')}
            active={location.pathname === '/pending-requests'}>
            <IconContainer><FaClock /></IconContainer> Bekleyen Talepler
          </NavButton>
          <NavButton 
            onClick={() => handleClick('Onaylanan Talepler', '/completed-requests')}
            active={location.pathname === '/completed-requests'}>
            <IconContainer><FaCheckCircle /></IconContainer> Onaylanan Talepler
          </NavButton>
          <NavButton 
            onClick={() => handleClick('Redlenen Talepler', '/rejected-requests')}
            active={location.pathname === '/rejected-requests'}>
            <IconContainer><FaTimesCircle /></IconContainer> Redlenen Talepler
          </NavButton>
          <NavButton 
            onClick={() => handleClick('Ayarlar', '/site-settings')}
            active={location.pathname === '/site-settings'}>
            <IconContainer><FaCog /></IconContainer> Ayarlar
          </NavButton>
          <Divider /> {/* Logout butonu için ayrım çizgisi */}
          <LogoutButton />
        </>
      ) : (
        <NavButton onClick={() => navigate('/login')} active={location.pathname === '/login'}>
          <IconContainer><FaSignInAlt /></IconContainer> Login
        </NavButton>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
