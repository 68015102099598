import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import styled, { keyframes } from 'styled-components';

const SiteSettings = () => {
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const fetchSiteSettings = () => {
    setLoading(true);
    axiosInstance.get('/siteSettings')
      .then(response => {
        setSettings(response.data);
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSiteSettings();
  }, []);

  const toggleBonusRequests = () => {
    if (!settings) return;
    
    const newStatus = !settings.bonusRequestsEnabled;
    setIsUpdating(true);
    axiosInstance.post('/siteSettings/update', { status: newStatus })
      .then(() => {
        setSettings(prev => ({ ...prev, bonusRequestsEnabled: newStatus }));
      })
      .catch(err => {
        console.error("Güncelleme hatası:", err);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  if (loading) return <Container><Message>Yükleniyor...</Message></Container>;
  if (error) return <Container><ErrorMessage>Hata: {error.message}</ErrorMessage></Container>;

  return (
    <Container redBackground={!settings.bonusRequestsEnabled}>
      <Title>🔧 Bonus Talepleri</Title>
      
      {!settings.bonusRequestsEnabled && (
        <WarningMessage>
          🚨 Bonus talepleri şu an kapalı. Otomatik ekleme yapılmamaktadır.
        </WarningMessage>
      )}
      
      <SettingCard>
        <SettingLabel>Durum:</SettingLabel>
        <SettingValue active={settings.bonusRequestsEnabled}>
          {settings.bonusRequestsEnabled ? "Aktif ✅" : "Kapalı ❌"}
        </SettingValue>
      </SettingCard>

      <ToggleButton 
        onClick={toggleBonusRequests} 
        active={settings.bonusRequestsEnabled} 
        disabled={isUpdating}
      >
        {isUpdating ? "Güncelleniyor..." : (settings.bonusRequestsEnabled ? "Kapat ❌" : "Aç ✅")}
      </ToggleButton>

      <RefreshButton 
        onClick={fetchSiteSettings} 
        disabled={isUpdating}
      >
        🔄 Yenile
      </RefreshButton>
    </Container>
  );
};

export default SiteSettings;

const fadeIn = keyframes`
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
`;

const Container = styled.div`
  max-width: 420px;
  margin: 60px auto;
  padding: 30px;
  background: ${({ redBackground }) => redBackground ? 'rgba(255, 0, 0, 0.1)' : 'linear-gradient(135deg, rgb(253, 253, 253), #ffffff)'};
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #333;
`;

const Title = styled.h2`
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  color: #222;
`;

const WarningMessage = styled.div`
  background: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const Message = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const ErrorMessage = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: red;
  text-align: center;
`;

const SettingCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
`;

const SettingLabel = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const SettingValue = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${({ active }) => (active ? '#4CAF50' : '#FF3B3B')};
`;

const ToggleButton = styled.button`
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  background: ${({ active }) => (active ? '#FF3B3B' : '#4CAF50')};
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  
  &:hover {
    background: ${({ active }) => (active ? '#D32F2F' : '#388E3C')};
  }

  &:disabled {
    background: gray;
    cursor: not-allowed;
  }
`;

const RefreshButton = styled.button`
  width: 100%;
  margin-top: 15px;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    background: gray;
    cursor: not-allowed;
  }
`;
