import React, { useEffect, useState } from 'react';
import DataTable from './DataTable';
import axiosInstance from '../utils/axiosInstance'; // Use your custom axios instance

const CompletedRequests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchApprovedRequests = () => {
    axiosInstance.get('/request/approved') // Ensure this endpoint returns the right data
      .then(response => {
        const approvedData = response.data.filter(request => request.status === 'approved');
        setData(approvedData);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {

    fetchApprovedRequests();

  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      {/* fetchPendingRequests fonksiyonunu DataTable'a prop olarak geçiyoruz */}
      <DataTable
        title="Onaylanan Talepler"
        data={data}
        isPendingPage={false}
        isCompletedPage={true} // ✅ Yeni prop
        fetchData={fetchApprovedRequests}
      />
    </div>
  );
};

export default CompletedRequests;
