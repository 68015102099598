import axios from 'axios';

const axiosInstance = axios.create({
  //baseURL: 'https://api.antikbet.dev:1881/', // Replace with your backend URL  baseURL: 'https://api.antikbet.dev:1881/'  / http://localhost:1881/
  baseURL: 'https://api.antikbet.dev:1881/',
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
